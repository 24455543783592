import React from "react";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Container } from "reactstrap";
// index sections

import SectionIntroText from "./index-sections/SectionIntroText";
import SectionVideo from "./index-sections/SectionVideo";
import SectionYoga from "./index-sections/SectionYoga";


function Index() {
  return (
    <>
      <IndexNavbar transparentUntilScrolled={true} />
      <LandingPageHeader />
    
      <Container>
        <SectionIntroText />
        
        <SectionYoga />
        <SectionVideo />
        
      </Container>
    </>
  );
}
export default Index;
