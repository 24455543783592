import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardLink } from "reactstrap";
// core components
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Gin() {

    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-4 mt-4">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="12">
                        <h2>Gin Tasting</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Blumig, grasig, kräuterig – ist nicht nur unser Alpakafutter, sondern auch der Gin, den ihr bei unserem
                            Tasting verkosten dürft.</h4>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Wie gestaltet sich der Ablauf von „Alpaka meets Gin“?</h3>
                            <p>Wir begrüßen euch mit einem kleinen Aperitif und entführen euch anschließend in die Welt der Alpakas:
                                Bei der gemeinsamen Fütterung lernt ihr viele interessante Facts über unsere flauschigen Vierbeiner. Im
                                Anschluss taucht ihr ein in die Welt des Gins. Bei einem Tasting nehmen wir euch mit auf eine
                                geschmackliche Reise, bei der wir Gin in verschiedenen Varianten vorstellen.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto text-center mt-4" >
                        <img className="img-fluid" alt="..." src={"/img/events/gin.jpg"} />
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was kostet „Alpaka meets Gin“?</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-pricing card-plain">
                            <CardBody>
                                <h6 className="card-category">Pro Person</h6>
                                <CardTitle tag="h3">€ 49.00</CardTitle>
                                <p className="card-description">
                                    Die Teilnahme ist ausschließlich für Personen ab 18 Jahren gestattet.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was muss ich noch vor einer Wanderung wissen?</h3>
                            <ul>
                                <li>Die Teilnahme ist ausschließlich für Personen ab 18 Jahren gestattet.</li>
                                <li>Alpakas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen können
                                    wir leider keine Hunde auf unserem Gelände gestatten.</li>
                                <li>Bring am besten wetterfeste Kleidung mit: Bei Regenwetter eine Regenjacke (keinen Schirm!) und
                                    wasserfeste Schuhe / Gummistiefel.</li>
                                <li> Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen, Glatteis, Schneesturm) müssen wir das
                                    Wohlergehen der Tiere immer in den Vordergrund stellen und behalten uns das Recht vor,
                                    Events auch kurzfristig abzusagen. Selbstverständlich sorgen wir bei einer ausgefallenen
                                    Events für Alternativtermine.</li>
                                <li>Bitte beachte unsere Regelungen für Stornierungen in unseren <CardLink to="/agb" tag={Link}>AGBs</CardLink>.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Firmenevents, größere Gruppen oder spezielle Wünsche?</h3>
                            <p>Am besten ihr kontaktiert uns per E-Mail (info@alpakas-vom-rosenhof.de). Wir melden uns bei euch, um alles Weitere zu besprechen. </p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3><strong>Termin buchen</strong></h3>
    
                        <iframe id="etifr" title="meet_calendar" src="https://www.etermin.net/alpakas-vom-rosenhof?servicegroupid=143113" height="1600px" width="100%" scrolling="no" frameBorder="0"></iframe><script src='https://www.eTermin.net/js/resizecustomersitescroll.min.js' type='text/javascript'></script>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Gin;
