import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardLink } from "reactstrap";
import { Link } from "react-router-dom";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Aperolwanderung() {

    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-4 mt-4">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Alpakerol Spritz Wanderung</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="6">
                        <div className="article-content">
                            <h3>Wie gestaltet sich der Ablauf von der Alpakerol Spritz Wanderung?</h3>
                            <p>Treffpunkt für die Tour ist direkt an der Alpakawiese in der Alten Wormser Straße 9 in 68623
                                Lampertheim Rosengarten. Gelegenheit zum Parken besteht direkt an der Wiese.</p>
                            <p>
                                Sobald alle Teilnehmer bereit sind, begeben wir uns gemeinsam auf die Wiese, um unsere liebenswerten
                                Alpakas und Lamas zu begrüßen. Nun werden die Tiere an die Wandergäste verteilt. In einer kleinen
                                Einführung erklärt unser Team, worauf ihr beim Wandern achten müsst. Anschließend verlassen wir das
                                Gelände und ihr kommt in den Genuss, die Natur zusammen mit eurem Tier zu erkunden. Zeit für Pausen
                                und Bilder ist ebenfalls eingeplant. Nach der Wanderung entlassen wir die Tiere gemeinsam wieder auf
                                ihre Koppel. Nun erwartet euch ein außergewöhnlicher entspannter Moment auf unserer Koppel. Inmitten
                                der ruhigen Natur haben wir eine ganz besondere Überraschung für euch – unser Aperol Spritz (mit
                                Alkohol und / oder der alkoholfreien Variante) wird auf einer charmanten alten Ape bereitgestellt, die dem
                                Ganzen eine rustikale und gemütliche Atmosphäre verleiht.</p>
                            <p>Genießt euren Aperol Spritz in Gesellschaft unserer Alpakas und Lamas. Beobachtet, wie sie sich auf der
                                Koppel entspannen, grasen und ihre niedlichen Eigenheiten zeigen. Es gibt keinen besseren Weg, diese
                                Mischung aus Natur, Tieren und Genuss zu erleben!</p>
                            <p>Für das Event sollte man 120 bis 150 Minuten Zeit einplanen.</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src={"/img/events/aperol-wanderung-1.jpg"} />
                        </Card>
                    </Col>
                </Row>
                <Row>

                </Row>

                <Row>
                    <Col className="ml-auto m-12">

                        <Col className="ml-auto mr-auto" md="12">
                            <div className="article-content">
                                <h3>Was kostet eine Wanderung?</h3>
                            </div>
                        </Col>

                        <Col className="ml-auto mr-auto" md="5">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h6 className="card-category">ALPAKAEROL SPRITZ WANDERUNG AB 18 JAHREN</h6>
                                    <CardTitle tag="h3">€ 40.00 pro Person (& pro Tier) inkl. Aperol Spritz und / oder alkoholfreiem Aperol</CardTitle>
                                    <p className="card-description">
                                        Dieses Event richtet sich ausschließlich an Erwachsene.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Col>

                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was muss ich noch vor einer Wanderung wissen?</h3>
                            <ul>
                                <li>Der Großteil unserer Wandertiere sind Alpakas. Zusätzlich nehmen wir auf jeder Wanderung wenige Lamas mit, da diese unterwegs die Führung der Gruppe übernehmen. Unser Team entscheidet vor jeder Wanderung, welche Gäste die sanften Riesen führen dürfen.</li>
                                <li>Alpakas und Lamas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen können
                                    wir leider keine Hunde auf unserem Gelände gestatten.</li>
                                <li>Bring am besten wetterfeste Kleidung mit: Bei Regenwetter eine Regenjacke (keinen Schirm!) und
                                    wasserfeste Schuhe / Gummistiefel.</li>
                                <li> Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen, Glatteis, Schneesturm) müssen wir das
                                    Wohlergehen der Tiere immer in den Vordergrund stellen und behalten uns das Recht vor,
                                    Wanderungen auch kurzfristig abzusagen. Selbstverständlich sorgen wir bei einer ausgefallenen
                                    Wanderung für Alternativtermine.</li>
                                <li>Bitte beachte unsere Regelungen für Stornierungen in unseren <CardLink to="/agb" tag={Link}>AGBs</CardLink>.</li>

                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Firmenevents, größere Gruppen oder spezielle Wünsche?</h3>
                            <p>Am besten ihr kontaktiert uns per E-Mail (info@alpakas-vom-rosenhof.de). Wir melden uns bei euch, um alles Weitere zu besprechen. </p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3><strong>Termin buchen</strong></h3>
                        <iframe id="etifr" title="meet_calendar" src="https://www.etermin.net/alpakas-vom-rosenhof?servicegroupid=159772" height="1600px" width="100%" scrolling="no" frameborder="0"></iframe><script src='https://www.eTermin.net/js/resizecustomersitescroll.min.js' type='text/javascript'></script>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Aperolwanderung;
